import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  margin-top: 140px;
  display: flex;
  justify-content: center;
`;

const Home = () => {
  return <Container></Container>;
};

export default Home;
