import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router';
import qs from 'query-string';
import NextButton from '../../components/NextButton';
import CalendarAccount from '../../components/CalendarAccount';
import GreenCalendar from '../../assets/green-calendar';
import Google from '../../assets/icons/google';
import Add from '../../assets/icons/add';
import { useActions, useStoreState } from '../../stores/onboarding/hooks';

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
`;
const Heading = styled.span`
  font-size: 26px;
  font-weight: 700;
  color: white;
`;
const Text = styled.span`
  font-size: 20px;
  color: rgba(255, 255, 255, 0.7);
`;
const Bottom = styled.div`
  margin-top: 45px;
`;
const GoogleButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px 0 16px;
  height: 60px;
  font-size: 20px;
  font-weight: 500;
  color: #757575;
  background-color: #fff;
  border-radius: 40px;
  border: none;
  outline: none;
  cursor: pointer;
`;

const AddAnotherContainer = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 20px;
  padding: 15px 15px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
`;
const AddIconCircle = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  border: 1px solid rgba(255, 255, 255, 0.3);
`;
const AddAnother = ({ onClick }) => (
  <AddAnotherContainer {...{ onClick }}>
    <AddIconCircle>
      <Add width={25} height={25} />
    </AddIconCircle>
    Add another account
  </AddAnotherContainer>
);

const CalendarSync = () => {
  const history = useHistory();
  const { search } = useLocation();

  const { googleAccounts } = useStoreState();
  const { redirectToGoogle, handleGoogleAuthenticate } = useActions();

  // remove search param from url after processing
  useEffect(() => {
    if (!search) return;

    const { code } = qs.parse(search);
    handleGoogleAuthenticate({ code });
    history.push('/onboarding');
  }, []);

  const handleGoogleClick = () => redirectToGoogle();

  return (
    <Container>
      <GreenCalendar />
      <Heading style={{ marginTop: 40 }}>Start by connecting your Google Calendar account</Heading>
      <Text style={{ marginTop: 20 }}>(it’s the only calendar we currently support)</Text>

      <Bottom>
        {googleAccounts.length === 0 ? (
          <GoogleButton onClick={handleGoogleClick}>
            <Google width={28} height={28} style={{ marginRight: 20 }} /> Sign in with Google
          </GoogleButton>
        ) : (
          <>
            {googleAccounts.map(({ id, profile }) => (
              <CalendarAccount key={id} profile={profile} />
            ))}
            <AddAnother onClick={handleGoogleClick} />
            <NextButton style={{ marginTop: 45 }}>Continue</NextButton>
          </>
        )}
      </Bottom>
    </Container>
  );
};

export default CalendarSync;
