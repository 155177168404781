import React from 'react';
import styled from 'styled-components';
import { useActions } from '../stores/onboarding/hooks';

const Button = styled.button`
  padding: 13px 20px;
  font-size: 16px;
  font-weight: 600;
  color: #171818;
  background-color: #36e199;
  border-radius: 8px;
  border: none;
  outline: none;
  cursor: pointer;
`;

const NextButton = ({ style, children }) => {
  const { goNext } = useActions();

  return (
    <Button {...{ style }} onClick={goNext}>
      {children}
    </Button>
  );
};

NextButton.defaultParams = { style: {} };

export default NextButton;
