import * as React from 'react';

export default props => (
  <svg width={56} height={56} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M0 10.806a3.818 3.818 0 013.818-3.818h34.364A3.818 3.818 0 0142 10.806V45.03a3.818 3.818 0 01-3.818 3.818H3.818A3.818 3.818 0 010 45.03V10.806z"
      fill="url(#prefix__paint0_linear)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.333 21.583a4.083 4.083 0 114.083 4.084 2.333 2.333 0 000 4.666 8.75 8.75 0 10-8.75-8.75V33.25a4.083 4.083 0 11-4.083-4.083 2.333 2.333 0 100-4.667 8.75 8.75 0 108.75 8.75V21.583z"
      fill="url(#prefix__paint1_linear)"
    />
    <defs>
      <linearGradient
        id="prefix__paint0_linear"
        x1={42.313}
        y1={-3.299}
        x2={-11.451}
        y2={31.611}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#202020" />
        <stop offset={1} stopColor="#333" />
      </linearGradient>
      <linearGradient
        id="prefix__paint1_linear"
        x1={36.357}
        y1={27.858}
        x2={5.264}
        y2={29.152}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#36E199" />
        <stop offset={1} stopColor="#0DBD73" />
      </linearGradient>
    </defs>
  </svg>
);
