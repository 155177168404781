import * as React from 'react';

export default props => (
  <svg width={56} height={56} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M0 20.808v23.97c0 3.668 3.033 6.701 6.702 6.701h29.154c3.669 0 6.702-3.033 6.702-6.701v-23.97H0z"
      fill="url(#prefix__paint0_linear)"
    />
    <rect x={8.75} y={4.521} width={5.25} height={10.5} rx={2.625} fill="#2C2C2C" />
    <rect x={28} y={4.521} width={5.25} height={10.5} rx={2.625} fill="#2C2C2C" />
    <path
      d="M28 8.873H14v3.523a2.625 2.625 0 01-5.25 0V8.873H6.702C3.032 8.873 0 11.906 0 15.574v5.234h42.607v-5.185c-.05-3.717-3.033-6.75-6.751-6.75H33.25v3.523a2.625 2.625 0 01-5.25 0V8.873z"
      fill="url(#prefix__paint1_linear)"
    />
    <path
      d="M26.855 30.592c.93 0 1.614.44 2.103 1.37.49.929.734 2.152.734 3.62 0 1.418-.245 2.543-.783 3.374-.538.832-1.223 1.223-2.152 1.223-.832 0-1.516-.39-2.006-1.174-.489-.782-.733-1.858-.733-3.18 0-3.472.93-5.233 2.837-5.233zm-6.849 5.38c0 2.495.636 4.452 1.957 5.822 1.32 1.37 2.935 2.103 4.892 2.103 1.956 0 3.522-.734 4.843-2.201 1.271-1.468 1.907-3.522 1.907-6.213 0-2.69-.587-4.793-1.81-6.31-1.223-1.516-2.886-2.299-4.99-2.299-2.151 0-3.863.832-5.038 2.446-1.125 1.663-1.76 3.864-1.76 6.653zm-8.315-2.837c.489-.293.929-.587 1.32-.929v3.082c0 1.076 0 2.348-.049 3.864-.048 1.517-.048 2.495-.048 2.886 0 .587.195 1.028.587 1.37.39.342.831.49 1.418.49.538 0 1.027-.148 1.419-.441.391-.294.587-.685.587-1.174v-4.647-9.05c0-.587-.294-1.076-.88-1.418-.294-.196-.686-.294-1.175-.294-.342 0-.831.147-1.467.49-.636.342-1.761 1.124-3.424 2.25a4.377 4.377 0 00-.832.782c-.196.245-.245.587-.245.978 0 .44.196.88.538 1.321.343.44.734.636 1.174.636.392.049.783-.049 1.077-.196z"
      fill="#36E199"
    />
    <defs>
      <linearGradient
        id="prefix__paint0_linear"
        x1={42.875}
        y1={13.271}
        x2={0}
        y2={51.771}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#202020" />
        <stop offset={1} stopColor="#333" />
      </linearGradient>
      <linearGradient
        id="prefix__paint1_linear"
        x1={42.875}
        y1={15.021}
        x2={0}
        y2={21.146}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#36E199" />
        <stop offset={1} stopColor="#0DBD73" />
      </linearGradient>
    </defs>
  </svg>
);
