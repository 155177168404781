import React from 'react';
import styled from 'styled-components';
import NextButton from '../../components/NextButton';
import RoutineIcon from '../../assets/routine';

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
`;
const Heading = styled.span`
  font-size: 26px;
  font-weight: 700;
  color: white;
`;
const Text = styled.span`
  font-size: 20px;
  color: rgba(255, 255, 255, 0.7);
`;
const Bottom = styled.div`
  margin-top: 60px;
`;

const RoutineContainer = styled.span`
  color: white;
  font-size: 18px;
  font-weight: 700;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 40px;
  padding: 6px 16px;
`;
const Routine = ({ emoji, text }) => {
  return (
    <RoutineContainer>
      <span style={{ marginRight: 8 }}>{emoji}</span>
      {text}
    </RoutineContainer>
  );
};

const EssentialRoutinesStart = () => {
  return (
    <Container>
      <RoutineIcon width={56} height={56} />
      <Heading style={{ marginTop: 40 }}>
        Let's get started with some essential routines to getting you into the groove.
      </Heading>

      <Text style={{ marginTop: 42 }}>
        1. Sleep it the ultimate food for your brain. We’ll set a <Routine emoji="💤" text="Bedtime" />
      </Text>
      <Text style={{ marginTop: 26 }}>
        2. Start your day strong by creating a <Routine emoji="️☀️" text="Morning flow" />
      </Text>
      <Text style={{ marginTop: 26 }}>
        3. <Routine emoji="️💪️" text="Exercise" /> to be healthier, look fitter, sleep easier, and work sharper
      </Text>

      <Bottom>
        <NextButton>Continue</NextButton>
      </Bottom>
    </Container>
  );
};

export default EssentialRoutinesStart;
