import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg width={56} height={56} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0 10.806a3.818 3.818 0 013.818-3.818h34.364A3.818 3.818 0 0142 10.806V45.03a3.818 3.818 0 01-3.818 3.818H3.818A3.818 3.818 0 010 45.03V10.806z"
        fill="url(#prefix__paint0_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.782 18.395a1.425 1.425 0 010-2.015l2.417-2.418a1.425 1.425 0 012.015 0l1.612 1.612 1.947-1.948a1.425 1.425 0 012.015 0l5.842 5.843a1.425 1.425 0 010 2.015l-1.947 1.947 1.612 1.612a1.425 1.425 0 010 2.015l-2.418 2.417a1.425 1.425 0 01-2.015 0L26.286 25.9 18.9 33.286l-3.928-3.928 7.387-7.387-3.576-3.576zM6.626 33.773a1.425 1.425 0 000 2.015l5.843 5.843a1.425 1.425 0 002.015 0l1.947-1.948 1.612 1.612a1.425 1.425 0 002.015 0l2.417-2.418a1.425 1.425 0 000-2.015l-11.08-11.08a1.425 1.425 0 00-2.015 0l-2.418 2.417a1.425 1.425 0 000 2.015l1.612 1.612-1.948 1.947z"
        fill="url(#prefix__paint1_linear)"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear"
          x1={42.313}
          y1={-3.299}
          x2={-11.451}
          y2={31.611}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#202020" />
          <stop offset={1} stopColor="#333" />
        </linearGradient>
        <linearGradient
          id="prefix__paint1_linear"
          x1={32.047}
          y1={16.606}
          x2={10.929}
          y2={40.792}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#36E199" />
          <stop offset={1} stopColor="#0DBD73" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgComponent;
