import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg width={26} height={26} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.95 5.172h-3.66v6.061H5.188v3.637h6.102v6.062h3.66V14.87h6.103v-3.637H14.95V5.172z"
        fill="#fff"
        opacity={0.3}
      />
    </svg>
  );
}

export default SvgComponent;
