import React, { useState } from 'react';
import styled from 'styled-components';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import CardSection from '../../components/StripeCardSection';
import Plan from '../../components/Plan';
import LogoWithText from '../../assets/logo-with-text';
import Logo from '../../assets/logo';
import call from '../../utils/call';
import { useActions, useStoreState } from '../../stores/onboarding/hooks';

const Container = styled.div`
  display: flex;
`;
const Left = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 100vh;
  width: 800px;
  background-color: #202122;
  z-index: 0;
`;
const Right = styled.div`
  width: calc(100vw - 800px);
  /* padding: 110px 160px 110px 100px; */
  background-color: #0d0d0d;
  z-index: 1;
`;

const StyledLogo = styled(LogoWithText)`
  position: absolute;
  top: 70px;
  left: 50px;
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  margin: 110px 100px;
  width: 400px;
`;
const Heading = styled.span`
  color: white;
  font-size: 24px;
  font-weight: 700;
`;
const FieldName = styled.span`
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 8px;
`;
const Info = styled.span`
  text-align: center;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);
`;

const Input = styled.input`
  color: white;
  background-color: #191919;
  border-radius: 4px;
  padding: 13px;
  outline: none;
  border: 2px solid #191919;

  &:focus {
    border: 2px solid #36e199;
  }
`;

const Subscribe = styled.button`
  padding: 13px 0;
  font-size: 16px;
  font-weight: 600;
  color: #36e199;
  background-color: #2a2b2e;
  border-radius: 4px;
  border: none;
  outline: none;
  cursor: pointer;

  &:hover {
    color: #36e19980;
    background-color: #212224;
  }

  &:focus {
    color: #36e19980;
    background-color: #212224;
  }
`;

const PaymentDetails = () => {
  const stripe = useStripe();
  const elements = useElements();

  const { startSubscription } = useActions();
  const { user } = useStoreState();

  const [email, setEmail] = useState(user.primaryEmail);
  const [name, setName] = useState('');

  const handleEmailChange = e => setEmail(e.target.value);
  const handleNameChange = e => setName(e.target.value);

  const handleSubcribe = async e => {
    e.preventDefault();

    const card = elements.getElement(CardElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card,
      billing_details: { email, name },
    });

    console.log(error);

    startSubscription(paymentMethod.id);
  };

  return (
    <Container>
      <Left>
        <StyledLogo />
        <Plan style={{ marginLeft: 120 }} />
        <Logo
          style={{ position: 'absolute', zIndex: -1, bottom: -92, right: -50 }}
          fill="#171717"
          width={360}
          height={440}
        />
      </Left>
      <Right>
        <FormContainer autoComplete="off" noValidate>
          <Heading>Pay with card</Heading>

          <FieldName style={{ marginTop: 30 }}>Email</FieldName>
          <Input type="email" autoComplete="nope" value={email} onChange={handleEmailChange} />

          <FieldName style={{ marginTop: 32 }}>Card information</FieldName>
          <CardSection />

          <FieldName style={{ marginTop: 32 }}>Name on card</FieldName>
          <Input type="email" value={name} onChange={handleNameChange} />

          <Subscribe disabled={!stripe} onClick={handleSubcribe} style={{ marginTop: 40 }}>
            Subscribe
          </Subscribe>

          <Info style={{ marginTop: 22 }}>
            By confirming your subscription, you allow Symplex Incorporated to charge your card for this payment and
            future payments in accordance with their terms.
          </Info>
        </FormContainer>
      </Right>
    </Container>
  );
};

export default PaymentDetails;
