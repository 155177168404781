import React from 'react';
import styled from 'styled-components';
import MultiSelect from '../../components/MultiSelect';
import NextButton from '../../components/NextButton';
import NotesIcon from '../../assets/notes';

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
`;
const Heading = styled.span`
  font-size: 24px;
  font-weight: 700;
  color: white;
`;
const Text = styled.span`
  font-size: 20px;
  color: rgba(255, 255, 255, 0.7);
`;
const Bottom = styled.div`
  margin-top: 60px;
`;

const AddMoreMindfulness1 = () => {
  return (
    <Container>
      <NotesIcon width={56} height={56} />
      <Heading style={{ marginTop: 40 }}>
        You want to be more intentional about your time by planning your days ahead and/or reflecting on days past. When
        would you prefer to plan?
      </Heading>

      <Text>Choose as many as you like</Text>

      <Bottom>
        <NextButton>Continue</NextButton>
      </Bottom>
    </Container>
  );
};

export default AddMoreMindfulness1;
