import React from 'react';
import styled from 'styled-components';
import Select from '../../components/Select';
import NextButton from '../../components/NextButton';
import FitnessIcon from '../../assets/fitness';

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
`;
const Heading = styled.span`
  font-size: 24px;
  font-weight: 700;
  color: white;
`;
const Text = styled.span`
  font-size: 20px;
  color: rgba(255, 255, 255, 0.7);
`;
const Bottom = styled.div`
  margin-top: 60px;
`;

const AddMoreLoseWeight = () => {
  return (
    <Container>
      <FitnessIcon width={56} height={56} />
      <Heading style={{ marginTop: 40 }}>
        Getting in shape is a lot less painful with a few small changes to your daily flow.
      </Heading>

      <Text>If you’re new to deep work start with something easier and work your way up</Text>

      <Select options={[{ id: 'keto', text: 'Keto' }]} value="keto" />

      <Bottom>
        <NextButton>Continue</NextButton>
      </Bottom>
    </Container>
  );
};

export default AddMoreLoseWeight;
