import * as React from 'react';

export default props => (
  <svg width={90} height={90} viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.93 18.306c-.856 7.104-4.887 12.913-8.827 18.306-3.94 5.393-7.757 10.268-8.061 15.233A19.207 19.207 0 0019.174 70.06c10.28 5.63 20.48 9.322 26.65 19.939 6-10.527 16.391-14.377 26.591-19.939a19.208 19.208 0 0010.02-18.216C81.67 40.294 65.47 32.255 65.65 18.306c-7.633 8.894-6.09 15.47-3.434 21.098 2.657 5.63 6.519 10.133 3.513 14.884-.06.134-.148.253-.259.349-.06.127-.145.242-.248.338a6.755 6.755 0 01-2.578 2.139 7.15 7.15 0 01-3.512.855 7.646 7.646 0 01-4.2-14.061L45.767 0 36.59 43.908a7.667 7.667 0 01-4.278 14.028 6.857 6.857 0 01-3.434-.856 7.105 7.105 0 01-2.657-2.139 1.126 1.126 0 01-.248-.338.44.44 0 01-.18-.349c-6.08-9.603 15.256-18.227.135-35.948z"
    />
  </svg>
);
