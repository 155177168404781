import React from 'react';
import styled from 'styled-components';
import NextButton from '../../components/NextButton';
import graphic1 from '../../assets/graphic1.png';

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
`;
const Text = styled.span`
  font-size: 20px;
  color: rgba(255, 255, 255, 0.9);
`;
const Bottom = styled.div`
  margin-top: 60px;
`;

const EssentialRoutinesGraphic = () => {
  return (
    <Container>
      <Text>Our system is based on daily incremental progress.</Text>
      <Text style={{ marginTop: 20 }}>
        Get 1% better each day over the next year, and you'll be 37x better at the end. This applies to all goals,
        whether it is losing weight, winning a race, or starting a business.
      </Text>

      <img style={{ marginTop: 60 }} src={graphic1} width="349" height="274" />

      <Bottom>
        <NextButton>Continue</NextButton>
      </Bottom>
    </Container>
  );
};

export default EssentialRoutinesGraphic;
