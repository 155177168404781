import * as React from 'react';

export default props => (
  <svg width={260} height={299} viewBox="0 0 260 299" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask id="prefix__a" maskUnits="userSpaceOnUse" x={0} y={0} width={260} height={299}>
      <rect width={260} height={299} rx={12} fill="url(#prefix__paint0_linear)" />
    </mask>
    <g opacity={0.4} stroke="#F6F5FA" mask="url(#prefix__a)">
      <path d="M84-5.5C106.667 5 70.17 97 180 97c58 0 45.5 75.167 80.5 80.5M147-30c22.667 10.5-13.829 102.5 96 102.5 58 0 45.5 75.167 80.5 80.5M-16 58.867c36.788-6.663 112.108-6.673 97 32.617C56 156.5 114.861 220.374 272 233" />
      <path d="M-84 83.1c45.474-8.223 138.578-8.235 119.903 40.253C5 203.59 77.759 282.418 272 298" />
      <path d="M-131 123.643c51.478-9.312 156.873-9.326 135.733 45.584C-30.25 260.088 52.115 349.355 272 367" />
    </g>
    <defs>
      <linearGradient
        id="prefix__paint0_linear"
        x1={255}
        y1={2.875}
        x2={102.902}
        y2={344.777}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#202122" />
        <stop offset={1} stopColor="#202122" />
      </linearGradient>
    </defs>
  </svg>
);
