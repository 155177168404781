import React from 'react';
import styled from 'styled-components';
import PlanBackground from '../assets/plan-background';
import Logo from '../assets/logo';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 256px;
  height: 296px;
  border-radius: 12px;
  background-color: #2a2c2e;
  border: 2px solid #36e199;
  box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.4);
`;

const Title = styled.span`
  margin-left: 34px;
  margin-top: 10px;
  color: white;
  font-size: 24px;
  font-weight: 700;
`;
const Info = styled.span`
  margin-left: 34px;
  margin-right: 40px;
  margin-top: 10px;
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  line-height: 22px;
`;

const PriceContainer = styled.span`
  margin-left: 34px;
  margin-top: 14px;
`;
const Currency = styled.span`
  color: rgba(255, 255, 255, 0.95);
  font-size: 24px;
`;
const Price = styled.span`
  color: rgba(255, 255, 255, 0.95);
  font-size: 48px;
  font-weight: 700;
`;
const Month = styled.span`
  color: rgba(255, 255, 255, 0.7);
  font-size: 24px;
`;

const Plan = ({ style }) => {
  return (
    <Container {...{ style }}>
      <Logo style={{ marginLeft: 34, marginTop: 40 }} width={60} height={60} fill="#b1c073" />
      <Title>Pro plan</Title>
      <Info>Includes weekly Zoom calls with a human goal specialist</Info>

      <PriceContainer>
        <Currency>$</Currency>
        <Price>100</Price>
        <Month>/month</Month>
      </PriceContainer>

      <PlanBackground style={{ position: 'absolute' }} />
    </Container>
  );
};

Plan.defaultProps = { style: {} };

export default Plan;
