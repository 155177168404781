import * as React from 'react';

export default props => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g opacity={0.5} clipPath="url(#prefix__clip0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.007 17.495l1.828-1.828L12.167 7 3.5 15.667l1.828 1.828 6.84-6.84 6.84 6.84z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="prefix__clip0">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
