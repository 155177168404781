import React from 'react';
import styled from 'styled-components';
import TickCircle from '../../assets/icons/tick-circle';

const Circle = styled.div`
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  border-radius: 16px;
  border: ${props => `2px solid ${props.color}`};
  color: ${props => props.color};
  user-select: none;
`;

export const ActiveMarker = ({ n }) => <Circle color="#36e199">{n}</Circle>;

export const IncompleteMarker = ({ n }) => <Circle color="rgba(255, 255, 255, 0.5)">{n}</Circle>;

export const CompleteMarker = () => <TickCircle width={32} height={32} />;
