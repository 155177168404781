import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg width={56} height={56} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#prefix__clip0)" fillRule="evenodd" clipRule="evenodd">
        <path
          d="M30.86 5.363c4.05 0 7.69 2.41 9.328 6.084C45.802 12.416 50 17.274 50 22.941c0 5.745-4.215 10.537-9.814 11.496-1.638 3.674-5.278 6.083-9.327 6.083-2.081 0-4.11-.651-5.826-1.852-2.75 2.195-5.388 3.099-7.66 3.1-4.65 0-7.695-3.83-6.9-7.237C4.54 33.85 0 28.91 0 22.941c0-5.744 4.215-10.537 9.814-11.495 1.638-3.674 5.278-6.083 9.327-6.083 2.094 0 4.134.658 5.859 1.874a10.167 10.167 0 015.86-1.874zM13.28 25.871h5.86a1.464 1.464 0 100-2.93h-2.323l3.358-3.358a1.463 1.463 0 00-1.035-2.501h-5.86a1.464 1.464 0 100 2.93h2.323l-3.358 3.358a1.463 1.463 0 001.035 2.501zM25 31.731h8.79a1.464 1.464 0 100-2.93h-5.254l6.289-6.289a1.463 1.463 0 00-1.036-2.5H25a1.464 1.464 0 100 2.93h5.253l-6.289 6.288A1.463 1.463 0 0025 31.73zM11.816 44.913a4.395 4.395 0 11-8.789 0 4.395 4.395 0 018.79 0z"
          fill="url(#prefix__paint0_linear)"
        />
        <path
          d="M13.281 25.871h5.86a1.464 1.464 0 100-2.93h-2.323l3.358-3.358a1.463 1.463 0 00-1.035-2.501h-5.86a1.464 1.464 0 100 2.93h2.324l-3.36 3.358a1.463 1.463 0 001.037 2.501zm11.72 5.86h8.788a1.464 1.464 0 100-2.93h-5.253l6.289-6.289a1.463 1.463 0 00-1.036-2.5H25a1.464 1.464 0 100 2.93h5.253l-6.288 6.288A1.463 1.463 0 0025 31.73z"
          fill="url(#prefix__paint1_linear)"
        />
      </g>
      <defs>
        <linearGradient
          id="prefix__paint0_linear"
          x1={50.373}
          y1={-5.436}
          x2={-8.629}
          y2={38.007}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#202020" />
          <stop offset={1} stopColor="#333" />
        </linearGradient>
        <linearGradient
          id="prefix__paint1_linear"
          x1={35.402}
          y1={24.628}
          x2={11.433}
          y2={26.163}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#36E199" />
          <stop offset={1} stopColor="#0DBD73" />
        </linearGradient>
        <clipPath id="prefix__clip0">
          <path fill="#fff" d="M0 0h56v56H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgComponent;
