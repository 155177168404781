import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import './index.css';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: 'white',
      // background: '#FFFFFF',
      // '::placeholder': {
      //   color: '#aab7c4',
      // },
    },
    invalid: {
      // color: '#fa755a',
      // iconColor: '#fa755a',
    },
  },
};

export default () => <CardElement options={CARD_ELEMENT_OPTIONS} />;
