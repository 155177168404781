import * as React from 'react';

export default props => (
  <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.34 7.5l8.327 8.5-8.327 8.5-2.38-2.333 4.408-4.5H6.667v-3.334h12.7l-4.408-4.5L17.34 7.5z"
    />
  </svg>
);
