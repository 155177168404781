import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg width={56} height={56} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0 10.806a3.818 3.818 0 013.818-3.818h34.364A3.818 3.818 0 0142 10.806V45.03a3.818 3.818 0 01-3.818 3.818H3.818A3.818 3.818 0 010 45.03V10.806z"
        fill="url(#prefix__paint0_linear)"
      />
      <path
        d="M24.108 15.43c2.199 0 4.06 1.449 4.68 3.444a4.001 4.001 0 013.648 3.986v2.1a4.002 4.002 0 010 7.231v2.1a4.001 4.001 0 01-4.18 3.997 4.895 4.895 0 01-4.148 2.29 1.96 1.96 0 01-1.96-1.96V17.39a1.96 1.96 0 011.96-1.96zM17.902 15.43c-2.2 0-4.06 1.449-4.68 3.444a4.001 4.001 0 00-3.648 3.986v2.1a4.001 4.001 0 000 7.231v2.1a4.001 4.001 0 004.18 3.997 4.896 4.896 0 004.148 2.29 1.96 1.96 0 001.96-1.96V17.39a1.96 1.96 0 00-1.96-1.96z"
        fill="#36E199"
      />
      <path
        d="M11.288 23.431a1.143 1.143 0 100 2.287 2.858 2.858 0 010 5.715 1.143 1.143 0 100 2.287c.535 0 1.05-.082 1.536-.234.032.383.103.753.214 1.104.337 1.067 1.074 2.033 2.232 2.422a1.143 1.143 0 10.728-2.167c-.296-.1-.603-.383-.78-.943-.176-.56-.186-1.312.08-2.105a5.144 5.144 0 00-4.01-8.366zM23.862 20.002a1.143 1.143 0 000 2.286c1.104 0 2.286 1.108 2.286 2.858 0 .06.005.12.014.179a5.144 5.144 0 003.987 8.395 1.143 1.143 0 000-2.287 2.858 2.858 0 110-5.715 1.143 1.143 0 000-2.287 5.13 5.13 0 00-1.88.355c-.514-2.094-2.189-3.784-4.407-3.784z"
        fill="#2C8D65"
        fillOpacity={0.4}
      />
      <g filter="url(#prefix__filter0_b)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.719 21.145c0-.631-.512-1.143-1.143-1.143-2.685 0-4.573 2.475-4.573 5.144a1.143 1.143 0 002.286 0c0-1.75 1.183-2.858 2.287-2.858.63 0 1.143-.512 1.143-1.143z"
          fill="#fff"
          fillOpacity={0.7}
        />
      </g>
      <g filter="url(#prefix__filter1_b)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.859 30.35a1.143 1.143 0 011.447.72c.404 1.201.43 2.452.094 3.52-.337 1.067-1.074 2.033-2.232 2.422a1.143 1.143 0 01-.729-2.167c.296-.1.604-.383.78-.943.177-.56.186-1.312-.08-2.105a1.143 1.143 0 01.72-1.447z"
          fill="#fff"
          fillOpacity={0.7}
        />
      </g>
      <defs>
        <filter
          id="prefix__filter0_b"
          x={8.43}
          y={15.429}
          width={14.861}
          height={15.432}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImage" stdDeviation={2.286} />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur" />
          <feBlend in="SourceGraphic" in2="effect1_backgroundBlur" result="shape" />
        </filter>
        <filter
          id="prefix__filter1_b"
          x={18.57}
          y={25.413}
          width={15.886}
          height={16.536}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImage" stdDeviation={2.286} />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur" />
          <feBlend in="SourceGraphic" in2="effect1_backgroundBlur" result="shape" />
        </filter>
        <linearGradient
          id="prefix__paint0_linear"
          x1={42.313}
          y1={-3.299}
          x2={-11.451}
          y2={31.611}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#202020" />
          <stop offset={1} stopColor="#333" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgComponent;
