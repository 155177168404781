import React from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { QueryParamProvider } from 'use-query-params';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import configureStore from './stores/configureStore';
import Home from './screens/Home';
import Onboarding from './screens/Onboarding';

const stripePromise = loadStripe('pk_test_tsnMx7JXsu4eGLn3k8mNT7oR00BFPld1Yb');

const { store, persistor } = configureStore();

const ConnectedApp = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
);

const App = () => {
  return (
    <Elements stripe={stripePromise}>
      <BrowserRouter>
        <QueryParamProvider ReactRouterRoute={Route}>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/onboarding" component={Onboarding} />
            <Redirect to="/" />
          </Switch>
        </QueryParamProvider>
      </BrowserRouter>
    </Elements>
  );
};

export default ConnectedApp;
