import * as React from 'react';

export default props => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g opacity={0.5} clipPath="url(#prefix__clip0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.007 7l1.828 1.828-8.668 8.667L3.5 8.828 5.328 7l6.84 6.84L19.007 7z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="prefix__clip0">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
