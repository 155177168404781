import React from 'react';
import styled from 'styled-components';
import Skip from '../../assets/icons/skip';
import { CompleteMarker, IncompleteMarker, ActiveMarker } from './HeadingMarkers';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 50px;
`;
const Top = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Bottom = styled.div`
  margin-top: 12px;
`;
const Left = styled.div`
  display: flex;
  align-items: center;
`;
const Right = styled.div`
  display: flex;
`;
const Text = styled.span`
  margin-left: 20px;
  font-weight: 600;
  font-size: 18px;
  color: ${props => (props.active ? '#36e199' : '#fff')};
  opacity: ${props => (props.active ? 1 : props.completed ? 0.2 : 0.5)};
  text-transform: uppercase;
  user-select: none;
`;

const Heading = ({ text, n, active, open, completed, children }) => {
  return (
    <Container>
      <Top>
        <Left>
          {active ? <ActiveMarker n={n} /> : completed ? <CompleteMarker /> : <IncompleteMarker n={n} />}

          <Text {...{ active, completed }}>{text}</Text>
        </Left>
        <Right>{active && !open && <Skip fill="rgba(255, 255, 255, 0.3)" />}</Right>
      </Top>

      {open && children && <Bottom>{children}</Bottom>}
    </Container>
  );
};

export default Heading;
