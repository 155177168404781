import React, { useState } from 'react';
import styled from 'styled-components';
import NextButton from '../../components/NextButton';
import Select from '../../components/Select';
import WeightsIcon from '../../assets/weights';

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
`;
const Heading = styled.span`
  font-size: 24px;
  font-weight: 700;
  color: white;
`;
const Bottom = styled.div`
  margin-top: 60px;
`;

const EssentialRoutinesExercise2 = () => {
  const [x, setX] = useState('biking');

  return (
    <Container>
      <WeightsIcon width={56} height={56} />
      <Heading style={{ marginTop: 40 }}>What’s your preferred exercise?</Heading>

      <Select
        options={[
          { id: 'biking', text: 'Biking' },
          { id: 'jogging', text: 'Jogging' },
          { id: 'running', text: 'Running' },
          { id: 'walking', text: 'Walking' },
          { id: 'swimming', text: 'Swimming' },
          { id: 'other', text: 'Other' },
        ]}
        value={x}
        onChange={setX}
      />

      <Bottom>
        <NextButton>Continue</NextButton>
      </Bottom>
    </Container>
  );
};

export default EssentialRoutinesExercise2;
