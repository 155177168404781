const ONBOARDING_STATES = {
  start: { start: true, next: 'calendar-sync' },
  'calendar-sync': {
    sidebar: { completed: [], active: 'calendar-sync' },
    next: 'congratulations',
    prev: 'start',
  },
  'essential-routines/graphic': {
    sidebar: { completed: ['calendar-sync'], active: 'essential-routines' },
    next: 'essential-routines/start',
    prev: 'calendar-sync',
  },
  'essential-routines/start': {
    sidebar: { completed: ['calendar-sync'], active: 'essential-routines' },
    next: 'essential-routines/bedtime',
    prev: 'essential-routines/graphic',
  },
  'essential-routines/bedtime': {
    sidebar: { completed: ['calendar-sync'], active: 'essential-routines', open: 'bedtime' },
    next: 'essential-routines/morning',
    prev: 'essential-routines/start',
  },
  'essential-routines/morning': {
    sidebar: { completed: ['calendar-sync'], active: 'essential-routines', open: 'morning' },
    next: 'essential-routines/exercise/1',
    prev: 'essential-routines/bedtime',
  },
  'essential-routines/exercise/1': {
    sidebar: { completed: ['calendar-sync'], active: 'essential-routines', open: 'exercise' },
    next: 'essential-routines/exercise/2',
    prev: 'essential-routines/morning',
  },
  'essential-routines/exercise/2': {
    sidebar: { completed: ['calendar-sync'], active: 'essential-routines', open: 'exercise' },
    next: 'add-more/selection',
    prev: 'essential-routines/exercise/1',
  },
  'add-more/selection': {
    sidebar: { completed: ['calendar-sync', 'essential-routines'], active: 'add-more' },
    next: 'add-more/lose-weight',
    prev: 'essential-routines/exercise/2',
  },
  'add-more/lose-weight': {
    predicate: qa => qa.routines.includes('lose-weight'),
    sidebar: { completed: ['calendar-sync', 'essential-routines'], active: 'add-more', open: 'lose-weight' },
    next: 'add-more/morning-ritual',
    prev: 'add-more/selection',
  },
  'add-more/morning-ritual': {
    predicate: qa => qa.routines.includes('morning-ritual'),
    sidebar: { completed: ['calendar-sync', 'essential-routines'], active: 'add-more', open: 'morning-ritual' },
    next: 'add-more/mindfulness/1',
    prev: 'add-more/lose-weight',
  },
  'add-more/mindfulness/1': {
    predicate: qa => qa.routines.includes('mindfulness'),
    sidebar: { completed: ['calendar-sync', 'essential-routines'], active: 'add-more', open: 'mindfulness' },
    next: 'add-more/mindfulness/2',
    prev: 'add-more/morning-ritual',
  },
  'add-more/mindfulness/2': {
    predicate: qa => qa.routines.includes('mindfulness'),
    sidebar: { completed: ['calendar-sync', 'essential-routines'], active: 'add-more', open: 'mindfulness' },
    next: 'add-more/shutdown',
    prev: 'add-more/mindfulness/1',
  },
  'add-more/shutdown': {
    predicate: qa => qa.routines.includes('shutdown'),
    sidebar: { completed: ['calendar-sync', 'essential-routines'], active: 'add-more', open: 'shutdown' },
    next: 'finish',
    prev: 'add-more/mindfulness/2',
  },
  finish: {
    sidebar: { completed: ['calendar-sync', 'essential-routines', 'add-more'], active: 'finish' },
    next: 'payment-details',
    prev: 'add-more/shutdown',
  },
  'payment-details': { end: true },
  congratulations: { end: true },
};

export const resolveSidebar = stage => ONBOARDING_STATES[stage].sidebar;
export const resolveNext = (stage, qa) => {
  let next = ONBOARDING_STATES[stage].next;

  while (true) {
    if (!next) return;

    console.log({ next });
    const { next: _next, predicate } = ONBOARDING_STATES[next];
    if (!predicate || predicate(qa)) return next;

    next = _next;
  }
};

export const resolvePrev = (stage, qa) => {
  let prev = ONBOARDING_STATES[stage].prev;

  while (true) {
    if (!prev) return;

    const { prev: _prev, predicate } = ONBOARDING_STATES[prev];
    if (!predicate || predicate(qa)) return prev;

    prev = _prev;
  }
};
