import React from 'react';
import styled from 'styled-components';
import Select from '../../components/Select';
import NextButton from '../../components/NextButton';
import FocusIcon from '../../assets/focus';

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
`;
const Heading = styled.span`
  font-size: 24px;
  font-weight: 700;
  color: white;
`;
const Text = styled.span`
  font-size: 20px;
  color: rgba(255, 255, 255, 0.7);
`;
const Bottom = styled.div`
  margin-top: 60px;
`;

const AddMoreMorningRitual = () => {
  return (
    <Container>
      <FocusIcon width={56} height={56} />
      <Heading style={{ marginTop: 40 }}>
        Context switching can kill the flow of creative thought. Schedule a deep work sesison away from all the
        distractions. It’s easiest to complete in the early morning when you have the maximum attention.
      </Heading>

      <Text>If you’re new to deep work start with something easier and work your way up</Text>

      <Select
        options={[
          { id: '30m', text: '30 Minutes' },
          { id: '1h', text: '1 Hour' },
          { id: '2h', text: '2 Hours' },
          { id: '3h', text: '3 Hours' },
        ]}
        value="30m"
      />

      <Bottom>
        <NextButton>Continue</NextButton>
      </Bottom>
    </Container>
  );
};

export default AddMoreMorningRitual;
