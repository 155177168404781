import React from 'react';
import styled from 'styled-components';
import Sidebar from '../../components/Sidebar';
import PageArrows from '../../components/PageArrows';
import { useStoreState } from '../../stores/onboarding/hooks';
import Start from './Start';
import CalendarSync from './CalendarSync';
import EssentialRoutinesGraphic from './EssentialRoutinesGraphic';
import EssentialRoutinesStart from './EssentialRoutinesStart';
import EssentialRoutinesBedtime from './EssentialRoutinesBedtime';
import EssentialRoutinesMorning from './EssentialRoutinesMorning';
import EssentialRoutinesExercise1 from './EssentialRoutinesExercise1';
import EssentialRoutinesExercise2 from './EssentialRoutinesExercise2';
import AddMoreSelection from './AddMoreSelection';
import AddMoreLoseWeight from './AddMoreLoseWeight';
import AddMoreMorningRitual from './AddMoreMorningRitual';
import AddMoreMindfulness1 from './AddMoreMindfulness1';
import AddMoreMindfulness2 from './AddMoreMindfulness2';
import AddMoreShutdown from './AddMoreShutdown';
import Finish from './Finish';
import PaymentDetails from './PaymentDetails';
import Congratulations from './Congratulations';

const Container = styled.div`
  display: flex;
`;
const Content = styled.div`
  position: relative;
  height: 100vh;
  width: ${props => (props.isEnd ? '100vw' : 'calc(100vw - 700px)')};
  padding: ${props => (props.isEnd ? '0' : '0 140px 0 120px')};
  background-color: #0d0d0d;
  z-index: 1;
`;

const getScreen = stage => {
  if (stage === 'start') {
    return Start;
  } else if (stage === 'calendar-sync') {
    return CalendarSync;
  } else if (stage === 'essential-routines/graphic') {
    return EssentialRoutinesGraphic;
  } else if (stage === 'essential-routines/start') {
    return EssentialRoutinesStart;
  } else if (stage === 'essential-routines/bedtime') {
    return EssentialRoutinesBedtime;
  } else if (stage === 'essential-routines/morning') {
    return EssentialRoutinesMorning;
  } else if (stage === 'essential-routines/exercise/1') {
    return EssentialRoutinesExercise1;
  } else if (stage === 'essential-routines/exercise/2') {
    return EssentialRoutinesExercise2;
  } else if (stage === 'add-more/selection') {
    return AddMoreSelection;
  } else if (stage === 'add-more/lose-weight') {
    return AddMoreLoseWeight;
  } else if (stage === 'add-more/morning-ritual') {
    return AddMoreMorningRitual;
  } else if (stage === 'add-more/mindfulness/1') {
    return AddMoreMindfulness1;
  } else if (stage === 'add-more/mindfulness/2') {
    return AddMoreMindfulness2;
  } else if (stage === 'add-more/shutdown') {
    return AddMoreShutdown;
  } else if (stage === 'finish') {
    return Finish;
  } else if (stage === 'payment-details') {
    return PaymentDetails;
  } else if (stage === 'congratulations') {
    return Congratulations;
  }
};

const Onboarding = () => {
  const { stage } = useStoreState();
  const Screen = getScreen(stage);

  const isEnd = stage === 'payment-details' || stage === 'congratulations';

  return (
    <Container>
      {!isEnd ? <Sidebar /> : null}
      <Content isEnd={isEnd}>
        <Screen />
        <PageArrows />
      </Content>
    </Container>
  );
};

export default Onboarding;
