import React from 'react';
import styled from 'styled-components';
import LogoWithText from '../../assets/logo-with-text';
import Logo from '../../assets/logo';
import Heading from './Heading';
import SubHeading from './SubHeading';
import { useStoreState } from '../../stores/onboarding/hooks';

const Container = styled.div`
  position: relative;
  height: 100vh;
  width: ${props => (props.payment ? '800px' : '440px')};
  background-color: #202122;
  z-index: 0;
`;

const StyledLogo = styled(LogoWithText)`
  padding: 70px 0 80px 50px;
`;

const IntroTextContainer = styled.div`
  margin: 40px 76px 0 50px;
`;
const IntroText = styled.span`
  font-size: 36px;
  font-weight: 600;
  color: white;
`;

const computeSidebarState = ({ completed, active, open }) => {
  return {
    'calendar-sync': {
      active: active === 'calendar-sync',
      completed: completed.includes('calendar-sync'),
    },
    'essential-routines': {
      active: active === 'essential-routines',
      completed: completed.includes('essential-routines'),
      open: ['bedtime', 'morning', 'exercise'].includes(open),
      bedtime: { active: open === 'bedtime' },
      morning: { active: open === 'morning' },
      exercise: { active: open === 'exercise' },
    },
    'add-more': {
      active: active === 'add-more',
      completed: completed.includes('add-more'),
      open: ['lose-weight', 'morning-ritual', 'mindfulness', 'shutdown'].includes(open),
      'lose-weight': { active: open === 'lose-weight' },
      'morning-ritual': { active: open === 'morning-ritual' },
      mindfulness: { active: open === 'mindfulness' },
      shutdown: { active: open === 'shutdown' },
    },
    finish: { active: active === 'finish' },
  };
};

const Sidebar = () => {
  const { stage, sidebar } = useStoreState();

  const isStart = stage === 'start';
  const isEnd = stage === 'payment-details' || stage === 'congratulations';

  const sidebarState = !isStart && !isEnd ? computeSidebarState(sidebar) : {};

  const calendarSync = sidebarState['calendar-sync'];
  const essentialRoutines = sidebarState['essential-routines'];
  const addMore = sidebarState['add-more'];
  const finish = sidebarState['finish'];

  return (
    <Container>
      <StyledLogo />

      {isStart ? (
        <IntroTextContainer>
          <IntroText>A few clicks away from optimizing your life.</IntroText>
        </IntroTextContainer>
      ) : (
        <>
          <Heading
            text="sync your calendar"
            n={1}
            completed={calendarSync.completed}
            active={calendarSync.active}
            open={calendarSync.open}
          />
          <Heading
            text="set essential routines"
            n={2}
            completed={essentialRoutines.completed}
            active={essentialRoutines.active}
            open={essentialRoutines.open}>
            <SubHeading text="Bed time" active={essentialRoutines['bedtime'].active} />
            <SubHeading text="Morning flow" active={essentialRoutines['morning'].active} />
            <SubHeading text="Exercise" active={essentialRoutines['exercise'].active} />
          </Heading>
          <Heading
            text="add more to your plan"
            n={3}
            completed={addMore.completed}
            active={addMore.active}
            open={addMore.open}>
            <SubHeading text="Lose weight" active={addMore['lose-weight'].active} />
            <SubHeading text="Morning ritual" active={addMore['morning-ritual'].active} />
            <SubHeading text="Practice mindfulness" active={addMore['mindfulness'].active} />
            <SubHeading text="Shutdown ritual" active={addMore['shutdown'].active} />
          </Heading>
          <Heading text="finish" n={4} completed={finish.completed} active={finish.active} open={finish.open} />
        </>
      )}
      <Logo
        style={{ position: 'absolute', zIndex: -1, bottom: -92, right: -50 }}
        fill="#171717"
        width={360}
        height={440}
      />
    </Container>
  );
};

export default Sidebar;
