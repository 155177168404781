import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg width={56} height={56} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0 10.806a3.818 3.818 0 013.818-3.818h34.364A3.818 3.818 0 0142 10.806V45.03a3.818 3.818 0 01-3.818 3.818H3.818A3.818 3.818 0 010 45.03V10.806z"
        fill="url(#prefix__paint0_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.554 13.836a1.34 1.34 0 10-2.483 1.008v.002l.004.008.017.041.064.166a22.83 22.83 0 01.822 2.606c.187.76.318 1.508.333 2.124.008.307-.014.542-.052.712-.036.16-.075.211-.075.211-.165.196-.497.439-1.024.688a8.88 8.88 0 01-.894.358l-2.625.495c-1.742.084-2.715-.075-4.456-.36l-.083-.013-.265-.042-.497-.081c-.762-.226-1.617-.529-1.931-.8-.532-.457-1.03-1.768-1.514-3.04-.095-.251-.19-.5-.285-.742a157.935 157.935 0 01-.934-2.447l-.058-.158-.02-.054a1.34 1.34 0 00-2.515.925l.021.059.06.162a142.247 142.247 0 00.951 2.49c.559 1.427 1.263 3.155 1.745 4.056.553 1.032 1.673 1.472 2.53 1.81.166.065.323.126.464.188.378.165.733.289 1.02.378v6.399c-1.543 4.089-1.561 8.309.712 12.055.296.486.801.753 1.317.753.283 0 .57-.081.827-.248.725-.475.944-1.469.487-2.223-1.26-2.074-1.844-4.591-1.214-7.521a.975.975 0 01.979-.785c.44.016.811.336.909.78.13.59.23 1.175.32 1.708.102.595.192 1.126.302 1.529.41 1.49 1.844 1.755 2.757 1.257 1.855-1.011 3.014-1.737 4.35-2.8.683-.54.78-1.57.244-2.264-.538-.696-1.357-.775-2.027-.218-.793.658-1.952 1.503-2.907 2.054-.186-.644-.31-1.416-.432-2.182-.084-.524-.167-1.044-.27-1.52l.021-6.338.18-.032c.456-.084 1.081-.215 1.758-.402a13.415 13.415 0 002.117-.763c.675-.319 1.404-.761 1.929-1.383.677-.802.779-1.89.758-2.72-.023-.893-.203-1.853-.412-2.698a25.493 25.493 0 00-.994-3.112l-.022-.054-.006-.015-.003-.007zm-6.1 3.838c0 1.55-1.212 2.805-2.705 2.805-1.494 0-2.705-1.256-2.705-2.805 0-1.549 1.211-2.804 2.705-2.804 1.493 0 2.704 1.255 2.704 2.804z"
        fill="url(#prefix__paint1_linear)"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear"
          x1={42.313}
          y1={-3.299}
          x2={-11.451}
          y2={31.611}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#202020" />
          <stop offset={1} stopColor="#333" />
        </linearGradient>
        <linearGradient
          id="prefix__paint1_linear"
          x1={30.119}
          y1={28.862}
          x2={9.603}
          y2={29.395}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#36E199" />
          <stop offset={1} stopColor="#0DBD73" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgComponent;
