import React, { useState } from 'react';
import styled from 'styled-components';
import MultiSelect from '../../components/MultiSelect';
import NextButton from '../../components/NextButton';

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
`;

const Heading = styled.span`
  font-size: 24px;
  font-weight: 700;
  color: white;
`;
const SubHeading = styled.span`
  font-size: 24px;
  color: white;
`;
const Text = styled.span`
  font-size: 20px;
  color: rgba(255, 255, 255, 0.7);
`;

const Bottom = styled.div`
  margin-top: 60px;
`;

const AddMoreSelection = () => {
  const [x, setX] = useState([]);
  const [y, setY] = useState([]);

  return (
    <Container>
      <Heading style={{ marginTop: 40 }}>Are there any other routines you would like to create?</Heading>
      <SubHeading>I want to...</SubHeading>
      <Text>Choose as many as you like</Text>

      <MultiSelect
        options={[
          { id: 'lose-weight', text: 'Lose weight' },
          { id: 'morning-ritual', text: 'Create a morning ritual' },
          { id: 'flexibility', text: 'Increase flexibility' },
          { id: 'mindfulness', text: 'Practice mindfulness' },
        ]}
        value={x}
        onChange={setX}
      />

      <Heading style={{ marginTop: 40 }}>
        With this system you can make consistent progress toward any new skill you want to pick up!
      </Heading>
      <SubHeading>I want to...</SubHeading>
      <Text>Choose as many as you like</Text>

      <MultiSelect
        options={[
          { id: 'lose-code', text: 'Learn to code' },
          { id: 'learn-instrument', text: 'Learn an instrument' },
          { id: 'sport', text: 'Practice a sport' },
        ]}
        value={y}
        onChange={setY}
      />

      <Bottom>
        <NextButton>Continue</NextButton>
      </Bottom>
    </Container>
  );
};

export default AddMoreSelection;
