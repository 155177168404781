import { useDispatch, useSelector } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';

export const useActions = () => {
  const dispatch = useDispatch();

  const redirectToGoogle = (...params) => dispatch(actions.redirectToGoogle(...params));
  const handleGoogleAuthenticate = (...params) => dispatch(actions.handleGoogleAuthenticate(...params));

  const setValue = (...params) => dispatch(actions.setValue(...params));
  const goNext = (...params) => dispatch(actions.goNext(...params));
  const goPrev = (...params) => dispatch(actions.goPrev(...params));

  const startSubscription = (...params) => dispatch(actions.startSubscription(...params));

  return { redirectToGoogle, handleGoogleAuthenticate, setValue, goNext, goPrev, startSubscription };
};

export const useStoreState = () => useSelector(selectors.storeState);
