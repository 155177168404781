import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  width: 360px;
  margin: 5px 0;
  padding: 16px;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 75px;
  cursor: pointer;
`;
const OptionText = styled.span`
  margin-left: 16px;
  color: white;
  font-size: 20px;
  user-select: none;
`;

const EmptyCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  border-radius: 8px;
  border: ${props => (props.selected ? '1px solid #36e199' : '1px solid #5e5e5e')};
`;
const MiddleCircle = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #36e199;
`;

const Option = ({ id, text, selected, onClick }) => {
  const handleClick = () => onClick(id);

  return (
    <OptionContainer onClick={handleClick}>
      {selected ? (
        <EmptyCircle {...{ selected }}>
          <MiddleCircle />
        </EmptyCircle>
      ) : (
        <EmptyCircle />
      )}

      <OptionText>{text}</OptionText>
    </OptionContainer>
  );
};

const Select = ({ options, value, style, onChange }) => {
  return (
    <Container {...{ style }}>
      {options.map(({ id, text }) => (
        <Option key={id} id={id} text={text} selected={id === value} onClick={onChange} />
      ))}
    </Container>
  );
};

Select.defaultProps = { style: {}, onChange: () => {} };

export default Select;
