import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg width={56} height={56} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        opacity={0.5}
        d="M22.124 48.267l-5.533 2.672a2.016 2.016 0 01-2.717-.989l-2.52-5.605a2.017 2.017 0 00-1.345-1.127l-5.954-1.51a2.018 2.018 0 01-1.446-2.5l1.67-5.915a2.016 2.016 0 00-.303-1.728L.381 26.581a2.016 2.016 0 01.502-2.847l5.081-3.457a2.017 2.017 0 00.875-1.518l.454-6.128a2.016 2.016 0 012.214-1.857l6.114.617a2.016 2.016 0 001.647-.599l4.287-4.403a2.016 2.016 0 012.891 0l4.285 4.403a2.016 2.016 0 001.648.6l6.113-.618a2.015 2.015 0 012.214 1.857l.454 6.13a2.015 2.015 0 00.877 1.517l5.081 3.456a2.016 2.016 0 01.5 2.847l-3.59 4.984a2.017 2.017 0 00-.305 1.728l1.67 5.912a2.015 2.015 0 01-1.446 2.503l-5.955 1.512a2.016 2.016 0 00-1.345 1.125l-2.52 5.605a2.014 2.014 0 01-2.716.989l-5.533-2.672a2.016 2.016 0 00-1.754 0z"
        fill="url(#prefix__paint0_linear)"
      />
      <path
        d="M4.854 29.101a18.148 18.148 0 1036.295 0 18.148 18.148 0 00-36.295 0z"
        fill="url(#prefix__paint1_linear)"
      />
      <path d="M8.887 29.1a14.115 14.115 0 1028.229 0 14.115 14.115 0 00-28.23 0z" fill="url(#prefix__paint2_linear)" />
      <path
        d="M10.902 29.101a12.099 12.099 0 1024.198 0 12.099 12.099 0 00-24.198 0z"
        fill="url(#prefix__paint3_linear)"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear"
          x1={46.29}
          y1={29.144}
          x2={-0.867}
          y2={31.058}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#36E199" />
          <stop offset={1} stopColor="#0DBD73" />
        </linearGradient>
        <linearGradient
          id="prefix__paint1_linear"
          x1={41.377}
          y1={29.65}
          x2={4.168}
          y2={31.139}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#36E199" />
          <stop offset={1} stopColor="#0DBD73" />
        </linearGradient>
        <linearGradient
          id="prefix__paint2_linear"
          x1={37.327}
          y1={8.049}
          x2={1.119}
          y2={31.481}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#202020" />
          <stop offset={1} stopColor="#333" />
        </linearGradient>
        <linearGradient
          id="prefix__paint3_linear"
          x1={35.251}
          y1={29.467}
          x2={10.445}
          y2={30.46}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#36E199" />
          <stop offset={1} stopColor="#0DBD73" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgComponent;
