import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
`;
const OptionContainer = styled.div`
  color: white;
  font-size: 20px;
  background-color: rgba(255, 255, 255, 0.05);
  margin: 8px 5px;
  padding: 12px 32px;
  user-select: none;
  cursor: pointer;
  border: ${props => (props.selected ? '1px solid #36e199' : '1px solid rgba(255, 255, 255, 0.05)')};
  border-radius: 75px;
`;

const Option = ({ id, text, selected, onClick }) => {
  const handleClick = () => onClick(id);

  return (
    <OptionContainer {...{ selected }} onClick={handleClick}>
      {text}
    </OptionContainer>
  );
};

const MultiSelect = ({ options, value, style, onChange }) => {
  const handleClick = id => {
    if (value.includes(id)) {
      onChange(value.filter(v => v !== id));
    } else {
      onChange([...value, id]);
    }
  };

  return (
    <Container {...{ style }}>
      {options.map(({ id, text }) => (
        <Option key={id} id={id} text={text} selected={value.includes(id)} onClick={handleClick} />
      ))}
    </Container>
  );
};

MultiSelect.defaultProps = { style: {}, onChange: () => {} };

export default MultiSelect;
