import createReducer from '../../utils/createReducer';
import { resolveSidebar, resolveNext, resolvePrev } from './utils';
import * as events from './events';

export const initialState = {
  session: null,
  user: null,
  googleAccounts: [],
  stage: 'start',
  next: 'calendar-sync',
  prev: null,
  sidebar: resolveSidebar('start'),
  qa: {
    bedtimeActual: '00:00',
    bedtimeGoal: '23:00',
    wakeupActual: '09:00',
    wakeupGoal: '08:00',
    exerciseTime: 'morning',
    exerciseType: 'cardio',
    preferredExercise: 'biking',
    routines: [],
    skills: [],
  },
};

export default createReducer(initialState, {
  [events.USER_AUTHENTICATED](state, event) {
    return {
      ...state,
      session: event.payload.session,
      user: event.payload.user,
      googleAccounts: event.payload.googleAccounts,
      stage: event.payload.user.stripeSubscriptionId ? 'congratulations' : state.stage,
    };
  },

  [events.CALENDAR_ADDED](state, event) {
    const { googleAccount } = event.payload;

    return {
      ...state,
      googleAccounts: [...state.googleAccounts.filter(a => a.id !== googleAccount.id), googleAccount],
    };
  },

  [events.VALUE_SET](state, event) {
    const { key, value } = event.payload;
    const qa = { ...state.qa, [key]: value };

    const sidebar = resolveSidebar(state.stage);
    const prev = resolvePrev(state.stage, qa);
    const next = resolveNext(state.stage, qa);

    return { ...state, sidebar, qa, prev, next };
  },
  [events.NEXT](state) {
    if (state.next) {
      const sidebar = resolveSidebar(state.next);
      const prev = resolvePrev(state.next, state.qa);
      const next = resolveNext(state.next, state.qa);

      console.log({ prev, next });
      return { ...state, sidebar, stage: state.next, prev, next };
    } else {
      return state;
    }
  },
  [events.PREV](state) {
    if (state.prev) {
      const sidebar = resolveSidebar(state.prev);
      const prev = resolvePrev(state.prev, state.qa);
      const next = resolveNext(state.prev, state.qa);

      return { ...state, sidebar, stage: state.prev, prev, next };
    } else {
      return state;
    }
  },
  [events.SUBSCRIPTION_STARTED](state) {
    return { ...state, stage: 'congratulations', prev: null, next: null };
  },
});
