import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 350px;
  height: 80px;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 50px;
  margin: 8px 0;
`;
const ProfileImage = styled.img`
  margin: 15px;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: #555;
`;
const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const NameText = styled.span`
  font-size: 20px;
  margin-bottom: 2px;
`;
const EmailText = styled.span`
  font-size: 16px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.4);
`;

// TODO: handle longer emails
const CalendarAccount = ({ profile }) => {
  return (
    <Container>
      <ProfileImage src={profile.photo} />
      <DetailsContainer>
        <NameText>{profile.name}</NameText>
        <EmailText>{profile.email}</EmailText>
      </DetailsContainer>
    </Container>
  );
};

export default CalendarAccount;
