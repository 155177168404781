import React from 'react';
import styled from 'styled-components';
import Skip from '../../assets/icons/skip';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 52px;
`;
const Text = styled.span`
  margin: 8px 0;
  font-weight: 500;
  font-size: 18px;
  color: ${props => (props.active ? '#36e199' : 'rgba(255, 255, 255, 0.5)')};
  user-select: none;
`;

const SubHeading = ({ active, text }) => {
  return (
    <Container>
      <Text {...{ active }}>{text}</Text>
      {active && <Skip fill="rgba(255, 255, 255, 0.3)" />}
    </Container>
  );
};

export default SubHeading;
