import React from 'react';
import styled from 'styled-components';
import Up from '../assets/icons/up';
import Down from '../assets/icons/down';
import { useActions, useStoreState } from '../stores/onboarding/hooks';

const Container = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  margin: 12px 20px;
`;

const ArrowContainer = styled.button`
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2a2b2e;
  border-radius: 8px;
  margin: 6px 0;
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.3);
  border: none;
  outline: none;
  cursor: pointer;
`;

const PageArrows = () => {
  const { googleAccounts, stage, prev, next } = useStoreState();
  const { goPrev, goNext } = useActions();

  const nextDisabled = stage === 'calendar-sync' && googleAccounts.length === 0;

  return (
    <Container>
      {prev && (
        <ArrowContainer onClick={goPrev}>
          <Up width={24} height={24} style={{ marginBottom: 3 }} />
        </ArrowContainer>
      )}

      {!nextDisabled && next && (
        <ArrowContainer onClick={goNext}>
          <Down width={24} height={24} style={{ marginTop: 3 }} />
        </ArrowContainer>
      )}
    </Container>
  );
};

export default PageArrows;
