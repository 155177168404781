import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg width={56} height={56} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0 10.806a3.818 3.818 0 013.818-3.818h34.364A3.818 3.818 0 0142 10.806V45.03a3.818 3.818 0 01-3.818 3.818H3.818A3.818 3.818 0 010 45.03V10.806z"
        fill="url(#prefix__paint0_linear)"
      />
      <path
        d="M11.888 21.738c-.213 0-.213.214-.213.214 0 .215 0 .215.213.215 1.712.64 2.78 1.925 3.208 3.421 0 .214.213.214.429.214 0 0 .213 0 .213-.213a4.821 4.821 0 013.208-3.206c.213 0 .213-.214.213-.214 0-.215 0-.215-.213-.215a4.822 4.822 0 01-3.208-3.208c0-.213-.213-.213-.213-.213-.216 0-.216 0-.216.213-.64 1.498-1.71 2.567-3.42 2.994v-.002zm11.121 7.914c-3.634 3.636-9.196 4.707-13.902 2.782a12.565 12.565 0 006.631 6.63c6.416 2.782 13.689-.214 16.468-6.631 2.784-6.416-.21-13.9-6.414-16.683 1.925 4.706.857 10.267-2.78 13.902h-.003z"
        fill="url(#prefix__paint1_linear)"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear"
          x1={42.313}
          y1={-3.299}
          x2={-11.451}
          y2={31.611}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#202020" />
          <stop offset={1} stopColor="#333" />
        </linearGradient>
        <linearGradient
          id="prefix__paint1_linear"
          x1={8.955}
          y1={28.295}
          x2={33.708}
          y2={29.277}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#36E199" />
          <stop offset={1} stopColor="#0DBD73" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgComponent;
