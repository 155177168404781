import { combineReducers } from 'redux';
import onboardingReducer from './onboarding/reducer';

export default () => {
  const reducerMap = {
    onboarding: onboardingReducer,
  };

  const appReducer = combineReducers(reducerMap);

  return (state, action) => {
    return appReducer(state, action);
  };
};
