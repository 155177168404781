import React from 'react';
import styled from 'styled-components';
import NextButton from '../../components/NextButton';
import graphic2 from '../../assets/graphic2.png';

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
`;
const Text = styled.span`
  font-size: 20px;
  color: rgba(255, 255, 255, 0.7);
`;
const Bottom = styled.div`
  margin-top: 60px;
`;

const Finish = () => {
  // TODO: catch click of finish button and dispatch action to submit answers
  // then we can animate to payment screen

  return (
    <Container>
      <Text>
        Success is the product of daily habits. Not once-in-a-lifetime transformations. Heads up, your outcomes will lag
        behind your habits.
      </Text>

      <img style={{ marginTop: 60 }} src={graphic2} width="405" height="257" />

      <Bottom>
        <NextButton>Finish</NextButton>
      </Bottom>
    </Container>
  );
};

export default Finish;
