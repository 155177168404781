import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg width={56} height={56} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0 10.806a3.818 3.818 0 013.818-3.818h34.364A3.818 3.818 0 0142 10.806V45.03a3.818 3.818 0 01-3.818 3.818H3.818A3.818 3.818 0 010 45.03V10.806z"
        fill="url(#prefix__paint0_linear)"
      />
      <path
        d="M28.009 28.563a6.826 6.826 0 01-6.793 6.126 6.826 6.826 0 01-6.793-6.126h13.586z"
        fill="url(#prefix__paint1_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.432 27.193c0-7.613 6.171-13.785 13.784-13.785S35 19.58 35 27.194 28.83 40.977 21.216 40.977 7.432 34.806 7.432 27.193zm19.84-6.615a4.45 4.45 0 00-3.166 1.312c-.316.315-.316 1.233 0 1.548a.808.808 0 001.142 0c.54-.54 1.26-.838 2.024-.838s1.483.297 2.024.838a.808.808 0 001.142 0c.315-.316.316-1.233 0-1.549a4.449 4.449 0 00-3.166-1.311zm-12.111 0a4.45 4.45 0 00-3.167 1.312c-.315.315-.316 1.233 0 1.548a.808.808 0 001.142 0c.541-.54 1.26-.838 2.024-.838.765 0 1.483.297 2.024.838a.808.808 0 001.143 0c.315-.316.315-1.233 0-1.549a4.45 4.45 0 00-3.166-1.311zM11.757 27.3a9.459 9.459 0 009.459 9.46 9.459 9.459 0 009.459-9.46.808.808 0 00-.808-.807H12.565a.808.808 0 00-.808.807z"
        fill="url(#prefix__paint2_linear)"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear"
          x1={42.313}
          y1={-3.299}
          x2={-11.451}
          y2={31.611}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#202020" />
          <stop offset={1} stopColor="#333" />
        </linearGradient>
        <linearGradient
          id="prefix__paint1_linear"
          x1={35.174}
          y1={27.61}
          x2={6.911}
          y2={28.741}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#36E199" />
          <stop offset={1} stopColor="#0DBD73" />
        </linearGradient>
        <linearGradient
          id="prefix__paint2_linear"
          x1={35.174}
          y1={27.61}
          x2={6.911}
          y2={28.741}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#36E199" />
          <stop offset={1} stopColor="#0DBD73" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgComponent;
