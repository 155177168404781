import React, { useState } from 'react';
import styled from 'styled-components';
import NextButton from '../../components/NextButton';
import Select from '../../components/Select';
import WeightsIcon from '../../assets/weights';

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
`;
const Heading = styled.span`
  font-size: 24px;
  font-weight: 700;
  color: white;
`;
const Text = styled.span`
  font-size: 20px;
  color: rgba(255, 255, 255, 0.7);
`;
const Bottom = styled.div`
  margin-top: 60px;
`;

const EssentialRoutinesExercise1 = () => {
  const [x, setX] = useState('morning');
  const [y, setY] = useState('cardio');

  return (
    <Container>
      <WeightsIcon width={56} height={56} />
      <Heading style={{ marginTop: 40 }}>
        Next up is exercise! Do you prefer to exercise in the morning or the evening?
      </Heading>

      <Select
        options={[
          { id: 'morning', text: 'Morning (schedule before work)' },
          { id: 'evening', text: 'Evening (schedule after work)' },
        ]}
        value={x}
        onChange={setX}
      />

      <Heading style={{ marginTop: 40 }}>Do you want to do cardio, bodyweight exercises, or weightlifting?</Heading>
      <Text style={{ marginTop: 10 }}>(Just to start, we can update this later)</Text>

      <Select
        options={[
          { id: 'cardio', text: 'Cardio' },
          { id: 'bodyweight-training', text: 'Bodyweight Training' },
          { id: 'weight-lifting', text: 'Weight lifting' },
        ]}
        value={y}
        onChange={setY}
      />

      <Bottom>
        <NextButton>Continue</NextButton>
      </Bottom>
    </Container>
  );
};

export default EssentialRoutinesExercise1;
