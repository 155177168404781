import * as events from './events';
import * as selectors from './selectors';
import call from '../../utils/call';

const getTimezone = () => window.Intl.DateTimeFormat().resolvedOptions().timeZone;

export const redirectToGoogle = () => async () => {
  try {
    const { url } = await call('GET', 'googleAccounts/generateOAuth2Url');
    window.location = url;
  } catch (err) {
    // error msg in bottom of screen
  }
};

export const handleGoogleAuthenticate = ({ code }) => async (dispatch, getState) => {
  const { session } = selectors.storeState(getState());

  if (session) {
    const googleAccount = await call('POST', 'googleAccounts/create', { userId: session.userId, code });

    dispatch({ type: events.CALENDAR_ADDED, payload: { googleAccount } });
  } else {
    const timezone = getTimezone();
    const _session = await call('POST', 'users/registerFromGoogleAuth', { code, timezone });
    const user = await call('GET', `users/${_session.userId}`);
    const googleAccounts = await call('GET', `googleAccounts/retrieveForUser?userId=${_session.userId}`);

    dispatch({ type: events.USER_AUTHENTICATED, payload: { session: _session, user, googleAccounts } });
  }
};

// This may change the store next and previous values as well
export const setValue = (key, value) => async dispatch => {
  dispatch({ type: events.VALUE_SET, payload: { key, value } });
};

export const goNext = () => async dispatch => {
  dispatch({ type: events.NEXT });
};

export const goPrev = () => async dispatch => {
  dispatch({ type: events.PREV });
};

export const startSubscription = paymentMethodId => async (dispatch, getState) => {
  const { user } = selectors.storeState(getState());

  try {
    await call('POST', `users/${user.id}/startSubscription`, { paymentMethodId });
    dispatch({ type: events.SUBSCRIPTION_STARTED });
  } catch (err) {
    console.log(err);
  }
};
