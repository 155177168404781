import * as React from 'react';

export default props => (
  <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={16} cy={16} r={16} fill="#36E199" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.499 17.09l6.449-6.608 2.125 2.177-8.574 8.786-5.57-5.707 2.125-2.177 3.445 3.53z"
      fill="#202122"
    />
  </svg>
);
