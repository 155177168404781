import React from 'react';
import styled from 'styled-components';
import Emoji from 'a11y-react-emoji';
import { useStoreState } from '../../stores/onboarding/hooks';
import LogoWithText from '../../assets/logo-with-text';
import Logo from '../../assets/logo';

const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Inner = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 760px;
  padding: 70px 0;
  border-radius: 12px;
  background-color: #191919;
  overflow: hidden;
`;

const StyledLogo = styled(LogoWithText)`
  position: absolute;
  top: 70px;
  left: 50px;
`;

const Heading = styled.span`
  z-index: 1;
  font-size: 40px;
  font-weight: 700;
  color: white;
`;

const Info = styled.span`
  z-index: 1;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  line-height: 32px;
  margin: 20px 100px 70px 100px;
`;

const PinCode = styled.span`
  z-index: 1;
  font-size: 40px;
  font-weight: 600;
  letter-spacing: 12px;
  color: white;
`;

const Congratulations = () => {
  const { user } = useStoreState();

  return (
    <Container>
      <StyledLogo />
      <Inner>
        <Heading style={{ marginTop: 40 }}>
          Congratulations <Emoji symbol="🎉" />
        </Heading>
        <Info>
          You're all set. Please use this pin code to enter the Demigod app. We’ve also sent the code to your email.
        </Info>

        <PinCode>{user.pinCode}</PinCode>

        <Logo style={{ position: 'absolute', bottom: -152, right: -90 }} fill="#121212" width={360} height={440} />
      </Inner>
    </Container>
  );
};

export default Congratulations;
