import React from 'react';
import styled from 'styled-components';
import Emoji from 'a11y-react-emoji';
import NextButton from '../../components/NextButton';
import Logo from '../../assets/logo';

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
`;
const Heading = styled.span`
  font-size: 40px;
  font-weight: 700;
  color: white;
`;
const Text = styled.span`
  font-size: 24px;
  color: rgba(255, 255, 255, 0.7);
`;
const Bottom = styled.div`
  margin-top: 60px;
`;

const Start = () => {
  return (
    <Container>
      <Logo fill="#36e199" width={90} height={90} />
      <Heading style={{ marginTop: 40 }}>
        Welcome to Demigod <Emoji symbol="🙌" />
      </Heading>
      <Text style={{ marginTop: 30 }}>Demigod is the ultimate life manager app to run your life.</Text>
      <Text style={{ marginTop: 10 }}>Let's spend a few minutes personalizing your experience!</Text>
      <Bottom>
        <NextButton>Let's do it!</NextButton>
      </Bottom>
    </Container>
  );
};

export default Start;
