import React from 'react';
import styled from 'styled-components';
import NextButton from '../../components/NextButton';
import SunIcon from '../../assets/sun';

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
`;
const Heading = styled.span`
  font-size: 24px;
  font-weight: 700;
  color: white;
`;
const Text = styled.span`
  font-size: 20px;
  color: rgba(255, 255, 255, 0.7);
`;
const Bottom = styled.div`
  margin-top: 60px;
`;

const EssentialRoutinesMorning = () => {
  return (
    <Container>
      <SunIcon width={56} height={56} />
      <Heading style={{ marginTop: 40 }}>
        Bedtime is a keystone habit. Sleeping early helps you wake up early, perform your morning ritual, and stay
        focused throughout the day.
      </Heading>

      <Text style={{ marginTop: 42 }}>On average, what time do you currently wake up?</Text>
      <Text style={{ marginTop: 26 }}>How early would you like to wake up?</Text>

      <Bottom>
        <NextButton>Continue</NextButton>
      </Bottom>
    </Container>
  );
};

export default EssentialRoutinesMorning;
