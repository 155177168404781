import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg width={56} height={56} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0 10.806a3.818 3.818 0 013.818-3.818h34.364A3.818 3.818 0 0142 10.806V45.03a3.818 3.818 0 01-3.818 3.818H3.818A3.818 3.818 0 010 45.03V10.806z"
        fill="url(#prefix__paint0_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.429 17.714a2.571 2.571 0 012.57-2.571h16.713a2.571 2.571 0 012.571 2.571v1.665a1.942 1.942 0 00-.274.232L19.086 31.765c-.354.36-.552.845-.552 1.35v3.496c0 1.065.863 1.929 1.928 1.929h3.524c.52 0 1.017-.21 1.38-.581l5.917-6.06v6.384a2.571 2.571 0 01-2.57 2.571H12a2.571 2.571 0 01-2.571-2.57v-20.57zm5.141 3.535c0-.533.432-.964.964-.964h9.642a.964.964 0 010 1.928h-9.642a.964.964 0 01-.964-.964zm.964 2.892a.964.964 0 100 1.929h5.785a.964.964 0 000-1.929h-5.785z"
        fill="url(#prefix__paint1_linear)"
      />
      <path
        d="M20.714 33.756c0-.205.082-.402.227-.547l7.898-7.871 2.755 2.745-7.9 7.873a.771.771 0 01-.544.225H20.97a.257.257 0 01-.257-.257v-2.168z"
        fill="url(#prefix__paint2_linear)"
      />
      <path
        d="M33.724 24.928a.727.727 0 010 1.032L32.38 27.3l-2.755-2.746 1.344-1.34a.733.733 0 011.036 0l1.719 1.714z"
        fill="url(#prefix__paint3_linear)"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear"
          x1={42.313}
          y1={-3.299}
          x2={-11.451}
          y2={31.611}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#202020" />
          <stop offset={1} stopColor="#333" />
        </linearGradient>
        <linearGradient
          id="prefix__paint1_linear"
          x1={34.093}
          y1={28.387}
          x2={8.962}
          y2={29.346}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#36E199" />
          <stop offset={1} stopColor="#0DBD73" />
        </linearGradient>
        <linearGradient
          id="prefix__paint2_linear"
          x1={34.093}
          y1={28.387}
          x2={8.962}
          y2={29.346}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#36E199" />
          <stop offset={1} stopColor="#0DBD73" />
        </linearGradient>
        <linearGradient
          id="prefix__paint3_linear"
          x1={34.093}
          y1={28.387}
          x2={8.962}
          y2={29.346}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#36E199" />
          <stop offset={1} stopColor="#0DBD73" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgComponent;
