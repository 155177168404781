import { applyMiddleware, createStore, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import rootReducer from './rootReducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const persistConfig = { key: '29', storage };

const persistedReducer = persistReducer(persistConfig, rootReducer());

export default () => {
  const middleware = [thunk];

  const store = createStore(persistedReducer, {}, composeEnhancers(applyMiddleware(...middleware)));
  const persistor = persistStore(store);

  return { store, persistor };
};
