import * as React from 'react';

export default props => (
  <svg width={28} height={28} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M27.727 11.258H26.6v-.06H14v5.605h7.915a8.402 8.402 0 01-7.915 5.6 8.4 8.4 0 010-16.801 8.345 8.345 0 015.574 2.125l3.958-3.957A13.93 13.93 0 0014 0a14 14 0 1013.727 11.258z"
      fill="#FFC107"
    />
    <path
      d="M1.613 7.485l4.602 3.37A8.395 8.395 0 0114 5.6a8.345 8.345 0 015.575 2.126l3.957-3.957A13.93 13.93 0 0014 0 13.992 13.992 0 001.613 7.485z"
      fill="#F72824"
    />
    <path
      d="M14 28c3.473 0 6.82-1.297 9.387-3.635L19.055 20.7A8.342 8.342 0 0114 22.4a8.393 8.393 0 01-7.892-5.562l-4.57 3.516A13.987 13.987 0 0014 28z"
      fill="#00A645"
    />
    <path
      d="M27.726 11.258H26.6v-.06H13.999v5.605h7.915a8.437 8.437 0 01-2.863 3.898l4.335 3.666C23.081 24.643 28 21.002 28 14.001c0-.936-.095-1.855-.274-2.741v-.002z"
      fill="#1D84F5"
    />
  </svg>
);
